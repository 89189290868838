import React, { FunctionComponent } from "react"
import { RecipePage } from "../../models"
import Card from "./card"
import * as styles from "./recipeGrid.module.scss"

interface GridProps {
  recipes: RecipePage[]
  hasBigCard?: boolean
}

const RecipeGrid: FunctionComponent<GridProps> = ({ recipes, hasBigCard }) => {
  return (
    <ul className={`${hasBigCard ? styles.gridEmphasized : ""} ` + styles.grid}>
      {recipes.map((recipe, index) => (
        <li className={styles.gridItem} key={index}>
          <Card
            recipe={recipe}
            isLargeCard={index === 0 || recipes.length < 5}
          />
        </li>
      ))}
    </ul>
  )
}

export default RecipeGrid
