import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { FunctionComponent } from "react"
import { RecipePage } from "../../models"
import * as styles from "./card.module.scss"

interface CardProps {
  recipe: RecipePage
  isLargeCard: boolean
}

const Card: FunctionComponent<CardProps> = ({ recipe, isLargeCard }) => {
  return (
    <Link to={`/${recipe.slug}`} className={styles.link}>
      <GatsbyImage
        alt={recipe.title}
        image={
          isLargeCard
            ? recipe.featuredImageLarge.localFile.childImageSharp
                .gatsbyImageData
            : recipe.featuredImage.localFile.childImageSharp.gatsbyImageData
        }
        data-pin-nopin="true"
        className={recipe.slug}
      />

      <div className={styles.cardtext}>
        <h3>{recipe.title}</h3>
        <div>{recipe.time}</div>
      </div>
    </Link>
  )
}

export default Card
