import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { FunctionComponent } from "react"
import Layout from "../components/Layout"
import RecipeGrid from "../components/RecipeGrid"
import Seo from "../components/Seo"
import { HomePage, QueryResult, RecipePage, Website } from "../models"

interface IndexPageProps {
  homePage: HomePage
}

interface HomePageQuery {
  result: QueryResult
  homePage: HomePage
  site: Website
}

const query = graphql`
  query featuredRecipes {
    result: allContentfulRecipe(sort: { order: DESC, fields: publishDate }) {
      nodes: edges {
        node: node {
          featuredImage: thumbnail {
            ...CardImage
          }
          featuredImageLarge: thumbnail {
            ...CardImageLarge
          }
          ...RecipeMetadata
        }
      }
    }
    homePage: contentfulHomePage {
      heroImage {
        ...HeroImage
      }
    }
    site {
      ...SiteMetadata
    }
  }
`

// eslint-disable-next-line no-empty-pattern
const IndexPage: FunctionComponent<IndexPageProps> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { result, homePage, site } = useStaticQuery<HomePageQuery>(query)
  const recipes: RecipePage[] = result.nodes.map((x) => x.node as RecipePage)

  return (
    <Layout showSearchbox={true} useH1Header={true}>
      <Seo />
      <div>
        <h2>{site.siteMetadata.headline}</h2>
        {/* <Img fluid={homePage.heroImage.fluid} /> */}
        <RecipeGrid recipes={recipes} hasBigCard={true}></RecipeGrid>
      </div>
    </Layout>
  )
}

export default IndexPage
